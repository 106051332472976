"use client";

import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";
import { ErrorComponent } from "./error-component";
import { NextError } from "./types";
import { Center } from "./layout-component";

export default (function Error(props) {
  useEffect(() => {
    Sentry.captureException(props.error);
  }, [props.error]);
  return (
    <Center maxWidth="md">
      <ErrorComponent />
    </Center>
  );
} satisfies NextError);
