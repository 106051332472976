"use client";

import { WarningTwoIcon } from "@chakra-ui/icons";
import { Flex, Heading, Text } from "@chakra-ui/react";

export function ErrorComponent() {
  return (
    <>
      <Heading>Oh nein!</Heading>
      <Text fontSize="6xl" mt={4} textAlign="center">
        <span role="img" aria-label="Bombe">
          💣
        </span>
      </Text>
      <Text mt={4}>Es ist etwas schief gelaufen!</Text>
      <Flex p={4} alignItems="center" rounded="md" bg="red.200" mt={4}>
        <WarningTwoIcon />
        <Text ml={4}>
          Bitte beachte, dass alte Browser wie z.B. Internet Explorer nicht mehr
          unterstützt werden. Nutze ggf. einen neueren Browser um moderne
          Webseiten in voller Pracht und mit höherer Geschwindigkeit genießen zu
          können!
        </Text>
      </Flex>
    </>
  );
}
